.profile-card-1{
    margin-top:20px;
}

.profile-card-1 h5{
    color:#a42558;
    font-weight:600;
}
.profile-card-1 p{
    font-size:14px;
    font-weight:300;
}
.profile-card-1 .card-img-block {
    width: 90%;
    margin: 0 auto;
    position: relative;
    top: -20px;
}
.profile-card-1 .card-img-block img{
    border-radius:5px;
    box-shadow:0 0 10px rgba(0,0,0,0.63);
}


.profile-card-2{
    margin-top:20px;
}

.profile-card-2 h5{
    color: #f47d4e;
    font-weight:600;
}
.profile-card-2 p{
    font-size:14px;
    font-weight:300;
}
.profile-card-2 .card-img-block {
    width: 90%;
    margin: 0 auto;
    position: relative;
    top: -20px;
}
.profile-card-2 .card-img-block img{
    border-radius:5px;
    box-shadow:0 0 10px rgba(0,0,0,0.63);
}


.profile-card-3{
    margin-top:20px;
}

.profile-card-3 h5{
    color: #59946d;
    font-weight:600;
}

.profile-card-3 p{
    font-size:14px;
    font-weight:300;
}
.profile-card-3 .card-img-block {
    width: 90%;
    margin: 0 auto;
    position: relative;
    top: -20px;
}
.profile-card-3 .card-img-block img{
    border-radius:5px;
    box-shadow:0 0 10px rgba(0,0,0,0.63);
}

.card-desc {
    font-weight: bold !important;
}