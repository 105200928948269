.works {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100vw;
  height: 75vh;
  scroll-snap-align: start;

  .arrow {
    height: 50px;
    position: absolute;
    cursor: pointer;

    &.left {
      left: 75px;
      transform: rotateY(180deg);

      @media (max-width: 950px) {
        left: 0px;
      }
    }

    &.right {
      right: 75px;

      @media (max-width: 950px) {
        right: 0px;
      }
    }
  }

  .slider {
    height: 400px;
    display: flex;
    position: absolute;
    left: 0;
    transition: all 1s ease-out;

    @media (max-width: 768px) {
      height: 100vh;
      justify-content: center;
    }

    .sliderContainer {
      width: 100vw;
      display: flex;
      align-items: center;
      justify-content: center;

      .item {
        width: 700px;
        height: 100%;
        background-color: white;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;

        @media (max-width: 768px) {
          width: 80%;
          height: 50vh;
          margin: 15px 0;
        }
        @media (min-width: 1130px) {
          width: 80%;
          height: 600px;
          margin: 15px 0;
        }

        //Flex isnt working on mobile
        .left {
          align-items: center;
          justify-content: center;
          flex-flow: column wrap;
          padding-top: 20px;
          text-align: center;

          h2 {
            font-size: 30px;
            @media (max-width: 768px) {
              font-size: 35px;
            }
          }
          p {
            font-size: 13px;
            @media (max-width: 768px) {
              display: none;
            }
          }
        }
        .right {
          display: flex;
          align-items: center;
          justify-content: center;
          overflow: hidden;
          height: 100%;

          @media (max-width: 768px) {
            height: 40vh;
          }
          img {
            max-height: 100%;
          }
        }
        img {
          max-width: 100%;
          padding: 2rem 0 2rem 0;
        }
      }
    }
  }
}
